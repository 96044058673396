import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Team from '@solid-ui-blocks/Teams/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const AboutUs01 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        {/* <Hero content={content['hero']} reverse /> */}
        <div style={styling.privacyPolicyContainer}>
          <h1>Privacy Policy</h1>
          <p style={styling.effectiveDate}>Last Update: 1/10/2023</p>
          <p>Triaxo Solutions (“we”, “us”, or “our”) is committed to protecting the privacy of individuals who visit our website or use our software services. This Privacy Policy outlines the types of personal information we collect, how we use it, and how we safeguard your information.</p>

          <h2 style={styling.heading}>1. Information We Collect:</h2>
          <ul style={styling.list}>
            <li>Personal Information: We may collect personal information such as your name, email address, phone number, and other contact details when you voluntarily provide them to us.</li>
            <li>Usage Data: We may automatically collect information about your interactions with our website or software services, including your IP address, browser type, operating system, and pages visited.</li>
          </ul>

          <h2 style={styling.heading}>2. How We Use Your Information:</h2>
          <ul style={styling.list}>
            <li>To Provide Services: We use your personal information to provide and improve our software services, respond to your inquiries, and communicate with you about updates or changes.</li>
            <li>Analytics: We may use usage data to analyze trends, monitor the effectiveness of our website and services, and enhance user experience.</li>
            <li>Marketing: With your consent, we may send you promotional emails about our products, services, or events.</li>
          </ul>

          <h2 style={styling.heading}>3. Data Sharing:</h2>
          <ul style={styling.list}>
            <li>Third-party Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you.</li>
            <li>Legal Compliance: We may disclose your information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</li>
          </ul>

          <h2 style={styling.heading}>4. Data Security:</h2>
          <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

          <h2 style={styling.heading}>5. Your Rights:</h2>
          <ul style={styling.list}>
            <li>You have the right to access, update, or delete your personal information. If you wish to exercise these rights, please contact us using the information provided below.</li>
            <li>You may also opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in our emails.</li>
          </ul>

          <h2 style={styling.heading}>6. Children’s Privacy:</h2>
          <p>Our website and services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children under 18. If you become aware that a child has provided us with personal information, please contact us, and we will take steps to delete such information.</p>

          <h2 style={styling.heading}>7. Changes to This Policy:</h2>
          <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly. We encourage you to review this Privacy Policy periodically for any changes.</p>

          <h2 style={styling.heading}>8. Contact Us:</h2>
          <p>If you have any questions or concerns about our Privacy Policy or practices, please <a href='/contact-us'>contact us.</a> </p>

          <p style={styling.consent}>By using our website or services, you consent to the collection and use of your information as described in this Privacy Policy.</p>
        </div>
      </Container>
      {/* <Divider space='5' /> */}
      {/* <Content content={content['content-one']} />
      <Divider space='5' />
      <Divider space='5' />
      <Team content={content['team']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.contentTwoContainer}>
        <Content content={content['content-two']} />
      </Container> */}
      {/* <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}
const styling = {
  privacyPolicyContainer: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    marginTop:"-100px"
  },
  heading: {
    fontSize: '1.5rem',
    marginTop: '20px',
  },
  list: {
    listStyleType: 'disc',
    marginLeft: '20px',
  },
  effectiveDate: {
    fontStyle: 'italic',
    marginBottom: '20px',
  },
  consent: {
    marginTop: '20px',
    fontStyle: 'italic',
    fontSize: '0.9rem',
  },
};
export const query = graphql`
  query privacyPolicies {
    allBlockContent(
      filter: { page: { in: ["site/privacy-policies", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default AboutUs01
